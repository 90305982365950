tenderEdit.controller('tenderEditCtrl', function (
    $scope,
    $rootScope,
    $state,
    classifierService,
    tenderService,
    unitsService,
    $mdDialog,
    ntFlash,
    validateService,
    formationService,
    tenderData,
    planData,
    isCreateMode,
    uibDatepickerConfig,
    currencyListService,
    exchangeService,
    negotiationListService,
    seoService,
    uuid4,
    md5,
    $stateParams,
    documentsService,
    fundersService,
    langService,
    $uibModal,
    $timeout,
    $window,
    MULTILINGUAL_PROCEDURES,
    agreementInstanceService,
    oraCodesService,
    loadExternalDataService,
    criteriaService,
     DATETIME_FROMAT,
    $filter
) {
    // TODO: We must add this in router !!!
    // if (!profileService.current) {
    //     $state.go('tendersList.type');
    //     return;
    // }

    $scope.OPC_MAIN_PROCUREMENT_CATEGORY = OPC_CONFIG.OPC_MAIN_PROCUREMENT_CATEGORY; // Remove after 11.12.2018
    $scope.isCZO = $window.OPC_CONFIG.PROCURING_ENTITY.kind === 'central';
    $scope.isDefense = $window.OPC_CONFIG.PROCURING_ENTITY.kind === 'defense';


    $scope.codes = [];
    oraCodesService.getCodesByCategory('UA')
        .then(c => $scope.codes = c);

    let currentState = $state.current.name;
    ntFlash.clean();

    $scope.critereiaFormValid = {
        criteriaValid: true,
        criteriaPristine: false,
        criteriaDirty: true,

    }
    $scope.config = {}
    $scope.MethodRationale = {
        methodRationale: [],
        methodRationaleCanEdit: true,
        currentMethodRationale: ''
    }

    $scope.reporting_cause = [{
        value: '',
        description: 'Без підстави'
    }]

    $scope.isCreateMode = isCreateMode;

    if ($state.params.entity == 'plan' && planData !== null && planData !== undefined) {
        console.log('planData', planData);              // ********** temporary ************************
        $scope.plan = planData.data.response;
        $scope.isComefromPlans = true;

    } else {
        console.log('tenderData', tenderData);          // ********** temporary ************************
        $scope.plan = null;
        $scope.isComefromPlans = false;
    }





    if ($scope.isCreateMode) {

        seoService.setTitle(gettext('Государственные закупки, тендеры Прозоро на Newtend'));
        seoService.setMetaDescription(gettext('Публичные электронные закупки Prozorro. Все государственные тендеры системы публичных электронных закупок на Ньютенд'));
        let newTender;
        console.log(tenderData)
        if (tenderData) {
            newTender = tenderService.copyTender(formationService.tender(tenderData.data));
            // newTender._guarantee = newTender.guarantee && newTender.guarantee.amount ? 'true' : '';
        } else {
            newTender = tenderService.newTender(
                $state.params.tenderProcedure,
                $state.params.lots === 'multilot'
            );
            // newTender._guarantee = '';
            newTender.procurementMethodType = $state.params.tenderProcedure;
            newTender.enquiryPeriod.startDate = new Date();
        }

        newTender.procuringEntity = {
            ...$window.OPC_CONFIG.PROCURING_ENTITY
        };
        if(newTender.procuringEntity.kind === 'defense' &&
            newTender.procurementMethodType === 'reporting'){
            newTender.procurementMethodRationale = 'defense'
        }
        if ($scope.isCZO) {
            newTender.buyers = [{
                identifier: {
                    scheme: 'UA-EDR',
                    id: '',
                    legalName: ''
                },
                name: ''
            }];
        }

        if(newTender.procurementMethodType === 'competitiveOrdering'){
            newTender.procurementMethod = "selective"
            $scope.lotRouter = "createTenderAgreement.lot"
        }




        tenderService.setTender(formationService.tender(newTender));

        $scope.actions = {
            tender_owner: true,
            can_edit_tender: true,
        };


        $scope.lotRouter = "editTender.lot";
        // $scope.CriteriaRouter = "editTender.criteria";
        $scope.qualityRouter = "editTender.quality";
        if(newTender.procurementMethodType === 'closeFrameworkAgreementSelectionUA' || newTender.procurementMethodType === 'competitiveOrdering'){
            $scope.lotRouter = "createTenderAgreement.lot"
        }
        $scope.config = tenderService.setDefaultConfig(newTender, isCreateMode)
        if(newTender.procuringEntity.kind === 'defense' &&
            newTender.procurementMethodType === 'competitiveOrdering'){
            $scope.config.restricted.value = true
        }
        console.log($scope.config)
    } else {
        tenderService.setTender(formationService.tender(tenderData.data));
        $scope.config = (tenderData.config) ? tenderData.config : {}

        $scope.actions = tenderData.action;
        if(tenderData.data.procurementMethodRationale === 'defense'){

            $scope.isDefense = true
        }
        $scope.lotRouter = "tenderView.edit.lot";
        $scope.qualityRouter = "tenderView.edit.quality";

    }




    $scope.actions = {
        tender_owner: true,
        can_edit_tender: true,
    };
    if (!$scope.actions.tender_owner && !$scope.actions.can_edit_tender) {
        return $state.go('tenderView.overview');
    }

    $scope.tender = tenderService.currentTender();
    $scope.MethodRationale = tenderService.setMethodRationale($scope.tender)
    // console.log($scope.MethodRationale)
    $scope.eco_criteria = $scope.tender.procurementMethodRationale === 'eco_criteria';
    if($scope.tender.procurementMethodType === 'reporting'){
        loadExternalDataService.reporting_cause()
            .then(data => {

                for (const [key, value] of Object.entries(data)) {
                    if(!value.archive){
                        const cause = {
                            value: key,
                            description: value.title_uk
                        }
                        $scope.reporting_cause.push(cause)
                    }

                }
                $scope.$apply($scope.reporting_cause)
            })
    }

    $scope.isCauseRequired = () => {


        if($scope.tender.procuringEntity.kind !== 'other'){
            // console.log($scope.tender)
            if(($scope.tender.mainProcurementCategory === 'goods' && $scope.tender.value.amount >= '100000')
            || ($scope.tender.mainProcurementCategory === 'services' && $scope.tender.value.amount >= '200000')
            || ($scope.tender.mainProcurementCategory === 'works'
                    && $scope.tender.value.amount >= '1500000'
                    && $scope.tender.procuringEntity.kind === "defense"
                    && $scope.procurementMethodRationale === "defense")

            ){
                $scope.reporting_cause = $scope.reporting_cause.filter((cause) => { return cause.description !== 'Без підстави'})
                return true
            }

        }
        // $scope.reporting_cause.push({
        //     value: '',
        //     description: 'Без підстави'
        // })
        // $scope.$apply($scope.reporting_cause)
        return false


    }

    if(!$scope.tender.milestones){
        $scope.tender.milestones = []
    }
    criteriaService.setTender($scope.tender);

    $scope.tanderSigned = $scope.tender.isSigned

    // if ($scope.tender.procurementMethodType === 'belowThreshold' && !$scope.tender.procurementMethodRationale)
    //     $scope.tender.procurementMethodRationale = '';

    console.log('$scope.tender2', angular.copy($scope.tender));


    if ($scope.isComefromPlans) {
        $scope.tender.title = $scope.plan.budget.description;
        $scope.tender.description = $scope.plan.budget.notes;
        $scope.tender.tenderPeriod.startDate = new Date($scope.plan.tender.tenderPeriod.startDate);
        $scope.tender.plans = [
            {
                id: $scope.plan.id
            }
        ];

        if ($scope.plan.items !== undefined) {
            if ($scope.tender.procurementMethodType !== 'reporting') {
                $scope.tender.lots[0] = {
                    id: md5.createHash(uuid4.generate()),
                    title: 'Лот № 1',
                    description: '',
                    guarantee: {
                        amount: 0,
                        currency: $scope.tender.procurementMethodType !== "esco" ? $scope.tender.value.currency : ''
                    },
                    value: {
                        amount: 0,
                        currency: $scope.tender.procurementMethodType !== "esco" ? $scope.tender.value.currency : '',
                    },
                    minimalStep: {
                        amount: 0,
                        currency: $scope.tender.procurementMethodType !== "esco" ? $scope.tender.value.currency : '',
                    }
                };
            }

            for (let i = 0; i < $scope.plan.items.length; i++) {
                $scope.tender.items[i] = $scope.plan.items[i];
                if ($scope.tender.procurementMethodType !== 'reporting') $scope.tender.items[i].relatedLot = $scope.tender.lots[0].id;
                $scope.tender.items[i].deliveryAddress = {
                    countryName: '',
                    postalCode: '',
                    region: '',
                    locality: '',
                    streetAddress: ''
                };
            }
        }
    }






    $scope.isEsco = $scope.tender.procurementMethodType === 'esco';
    // $scope.canEco = () =>{
    //     return $scope.isPMT([
    //         'aboveThreshold',
    //         'aboveThresholdUA',
    //         'aboveThresholdEU',
    //         'closeFrameworkAgreementUA',
    //         'esco',
    //         'competitiveDialogueUA',
    //         'competitiveDialogueEU',
    //         'simple.defense'
    //     ])
    // }
    $scope.setMethodRationale = (value) => {
        $scope.tender.procurementMethodRationale = value
    }

    $scope.isMultilotTender = $scope.tender.lots !== undefined;

    $scope.isSingleLot = ($scope.isMultilotTender) ? $scope.tender.lots.length < 2 : false
    criteriaService.setMultilotTender($scope.isMultilotTender)
    criteriaService.setSingleLot($scope.isSingleLot)
    // $scope.isTender = $scope.tender.lots.length !== 0;

    $rootScope.canRemoveLot = $scope.isCreateMode; // Fixme: add check

    $scope.currencies = currencyListService.getCurrenciesList();
    $scope.isInputHide = ($scope.isMultilotTender || $scope.isCompDialogStage2) ? false : true;

    $scope.isCloseFrameworkAgreement = $scope.tender.procurementMethodType === 'closeFrameworkAgreementSelectionUA' ? true : false;
    $scope.isCompetitiveOrdering = $scope.tender.procurementMethodType === 'competitiveOrdering' ? true : false;
    $scope.isCreateModeOnCloseFrameworkAgreement = $scope.isCloseFrameworkAgreement && $scope.isCreateMode;
    // --- Init for SelectionUA ---
    $scope.agreementId = $stateParams.agreementId;


    if ($scope.isCloseFrameworkAgreement && $scope.isCreateMode) {
        this.agreementInstanceService = agreementInstanceService;

        $scope.toogleUserInfo = (item) => {
            item._toggle = !item._toggle;
        };

        this.agreementInstanceService.getAgreements($scope.agreementId).then((res) => {
            if (res) {
                $scope.tender.agreements = [];
                $scope.tender.agreements.push({
                    id: $scope.agreementId
                });
                var tenderId = res.response.tender_id;
                const items = res.response.items;
                items.forEach((c) => {
                    c._toggle = false;
                });
                $scope.tenderAgreementItems = [...items];
                $scope.tender.items = [...items];
                console.log($scope.tenderAgreementItems)
                tenderService.get(tenderId).then((res) => {
                    if (res) {

                        $scope.tender.lots = res.data.lots.map((lot)=>{
                            return {
                                title: lot.title,
                                description: lot.description,
                                id: lot.id
                            }
                        });
                    }
                    $scope.isMultilotTender = $scope.tender.lots !== undefined;
                });


            }
        });

        $scope.editTenderItem = (id) => {
            const deleteItem = $scope.tender.items.some((el) => el.id === id);
            if (deleteItem) {
                const index = $scope.tender.items.findIndex((o) => o.id === id);
                if (index !== -1) $scope.tender.items.splice(index, 1);
            } else {
                const item = $scope.tenderAgreementItems.find((el) => el.id === id);
                $scope.tender.items.push(item);
            }
        };

    }

    if ($scope.isCompetitiveOrdering && $scope.isCreateMode) {
        this.agreementInstanceService = agreementInstanceService;

        $scope.toogleUserInfo = (item) => {
            item._toggle = !item._toggle;
        };

        this.agreementInstanceService.getAgreements($scope.agreementId).then((res) => {
            console.log(res)
            if (res) {
                $scope.tender.agreements = [];
                $scope.tender.agreements.push({
                    id: $scope.agreementId
                });
                console.log($scope.tender.procuringEntity)
                console.log(res.response.procuringEntity)
                if($scope.tender.procuringEntity.identifier.id !== res.response.procuringEntity.identifier.id){
                    ntFlash.error('Не співпадають ваші ЕДРПОУ компанії з поточною угодою');
                    $scope.disablePublishBtn = true
                }
            }
        });




    }





    // todo move to utils f textOverflow
    const textOverflow = (str, charAmount) => {
        if (str.length > charAmount)
             return str.slice(0, charAmount) + ". . .";
        return str;
    };

    const updateLotDescrption = (lot, lotItems) => {
        const descLength = 70;
        const lotDescriptionEdit = (lot, items) => {
            let lotDescription = items.filter(item => lot.id === item.relatedLot);
            lot.descriptionConcrete = [];
            lotDescription.forEach((el) => {
                if (el.description.length > descLength) {
                    lot.descriptionConcrete.push(textOverflow(el.description, descLength));
                } else {
                    lot.descriptionConcrete.push(el.description);
                }
            });
        };
        if (Array.isArray(lot)) {
            lot.forEach((lotItem) => {
                lotDescriptionEdit(lotItem, lotItems);
            });
        } else {
            lotDescriptionEdit(lot, lotItems);
        }
    };

    // this construction beacause procces of creating and editing not separated
    if (tenderData) {
        if (tenderData.data.lots) {
            updateLotDescrption(tenderData.data.lots, tenderData.data.items);
        }
    }
    // --- End fo SelectionUA ---

    if ($scope.tender.procurementMethodType === 'negotiation') {
        $scope.reasons = negotiationListService.getReasonsList();
    } else if ($scope.tender.procurementMethodType === 'negotiation.quick') {
        $scope.reasons = negotiationListService.getQuickReasonsList();
    }
    ($scope.reasons || []).forEach((el) => {
        el.descriptionShort = (el.parent !== 'None' ? ' --- ' : '') + (el.archive ? ' (A) ' : '') + textOverflow(el.description, 90);
    });

    let EU_LIMIT_EUR = 35000;
    exchangeService.getExchnageLimits(EU_LIMIT_EUR)
        .then((data) => {
            $scope.euLimits = data;
        });

    if ($scope.tender.enquiryPeriod) {
        uibDatepickerConfig.minDate = new Date($scope.tender.enquiryPeriod.startDate);
    }

    $scope.isMultilingual = MULTILINGUAL_PROCEDURES.has($scope.tender.procurementMethodType);

    let checkMultilingualRequired = (isAboveEuLimit) => {
        if ($scope.tender.procurementMethodType === 'aboveThresholdUA.defense' || $scope.tender.procurementMethodType === 'simple.defense') {
            return isAboveEuLimit;
        } else {
            return $scope.isMultilingual;
        }
    };

    $scope.isMultilingualRequired = checkMultilingualRequired();

    $scope.tender._guarantee = $scope.tender.guarantee && $scope.tender.guarantee.amount ? 'true' : '';

    uibDatepickerConfig.minDate = tenderService.isProcurementMethodType($scope.tender, ['reporting', 'negotiation', 'negotiation.quick']) ? null : new Date();

    const isLimited = tenderService.isLimitedByMethodType($scope.tender.procurementMethodType);
    $scope.minDateDeliveryStart = isLimited ? 'null' : uibDatepickerConfig.minDate;


    $scope.isPMT = (name) => tenderService.isProcurementMethodType($scope.tender, name);

    // TODO: Maybe remove this after replace to isPMT() ???
    $scope.isCompetitiveDialogue = tenderService.isCompetitiveDialogue($scope.tender);
    $scope.isCompDialogStage2 = tenderService.isCompetitiveDialogue2Stage($scope.tender);

    if ($scope.tender.procurementMethodType === 'belowThreshold' || $scope.tender.procurementMethodType === 'reporting') {

        $scope.inspectorLogic = (scheme) => {
            console.log(scheme)
            $scope.tender.inspector = $scope.tender.funders.find((founder) => founder.identifier.id === scheme)

        };
        // -----------------------------------
    }

     $scope.funderSelect = '0000';
        $scope.inspectorSelect = '0000'
        // --- funders ----------------------

        $scope.tender.funders = $scope.tender.funders || [];

        if (!$scope.tender.funders || $scope.tender.funders.length === 0) {
            $scope.funderSelect = ['0000'];
            $scope.inspectorSelect = '0000'


        } else {
            $scope.funderSelect = $scope.tender.funders.map((funder) => funder.identifier.id);
            if($scope.tender.inspector){
                $scope.inspectorSelect = $scope.tender.inspector.identifier.id;

            }
        }

 const WITHOUT_FUNDER = '__without__';
    let currentLang = langService.getCurrentLang();
        fundersService.getFunders(currentLang)
            .then(function (funders) {
                let fundersList = {
                    [WITHOUT_FUNDER]: {
                        identifier: {
                            id: '0000',
                            legalName: gettext('Без донора закупки')
                        }
                    },
                };
                for (let i = 0; i < funders.length; i++) {
                    fundersList[funders[i].identifier.scheme + funders[i].identifier.id] = funders[i];
                }
                $scope.funders = fundersList;
            });

        $scope.changeFunder = (scheme) => {

            const funders = Object.values($scope.funders)
            $scope.tender.funders = [];
            $scope.inspectorSelect = ''
            if (scheme) {
                $scope.tender.funders = []
                scheme.forEach((key) =>{
                    if(key !== '0000'){
                        $scope.tender.funders.push(funders.find((founder) => founder.identifier.id === key))

                    }
                })
            }
        };



    let showAttachDocsModal = (tenderInfo) => {

        // TODO Return to normal state
        return $uibModal.open({
            templateUrl: '/static/scripts/modules/TenderEdit/views/modals/tenderCreateAttachDocsModal.html',
            controller: 'attachDocumentsModalCtrl',
            resolve: {
                data: () => {
                    return tenderInfo;
                }
            },
            windowClass: 'center-modal',
            backdrop: 'static'
        });
    };

    // $scope.negotiationTypeCheck = function () {
    //     if ($scope.tender.cause === 'quick') {
    //         $scope.tender.procurementMethodType = 'negotiation.quick';
    //     } else {
    //         $scope.tender.procurementMethodType = 'negotiation';
    //     }
    // };

    $scope.validateItemsCpvByCategory = () => {
        if ($scope.tender.mainProcurementCategory) {
            if (!validateService.isValidItemsCpvByCategory($scope.tender.mainProcurementCategory, $scope.tender.items)) {
                ntFlash.error('Вид предмету закупівлі може не відповідати вказаному коду CPV(Єдиний закупівельний словник) розглянте можливість його зміни');
                return false;
            }
        }
        return true;
    };

    $scope.checkDefenseReporting = () => {
        return $scope.tender.procuringEntity.kind === 'defense' && $scope.tender.procurementMethodType === 'reporting'
    }

    function milestonesSumValid() {
        var total = 0;
        $scope.tender.milestones.forEach(milestone => {
            if (milestone.percentage) {
                total += milestone.percentage;
            }
        });
        return total == 100;
    }

    function clarifyAdditionalClassifications(item) {
        let isUAROAD = classifierService.isUAROAD(item);
        let isGMDN = classifierService.isGMDN(item);
        let isMOZ = classifierService.isMOZ(item);
        let resultArray = [];

        if (!isUAROAD && !isGMDN && !isMOZ) {
            resultArray = item.additionalClassifications.filter(elem => ['ДКПП', 'ДК003', 'ДК015', 'ДК018', 'КЕКВ', 'specialNorms'].includes(elem.scheme));
        } else if (isUAROAD) {
            resultArray = item.additionalClassifications.filter(elem => elem.scheme === 'UA-ROAD');
        } else if (isGMDN && isMOZ) {
            let _GMDN = item.additionalClassifications.filter(elem => elem.scheme === 'GMDN');
            let _ATCINN = item.additionalClassifications.filter(elem => ['ATC', 'INN'].includes(elem.scheme));
            resultArray = _GMDN.length ? _GMDN : _ATCINN;
        } else if (isGMDN) {
            resultArray = item.additionalClassifications.filter(elem => elem.scheme === 'GMDN');
        } else if (isMOZ) {
            resultArray = item.additionalClassifications.filter(elem => ['ATC', 'INN'].includes(elem.scheme));
        } else
            return item.additionalClassifications;
        return resultArray;
    }

    function prefixClassifier(cl) {
        let prefix;
        if (cl) {
            prefix = cl.substr(0, 4);
            if (prefix.substr(0, 3) === '336') {
                prefix = '336';
            }
            return prefix;
        }
    }


    $scope.sign = function (checkPerson = true){
        const data = {
            tenderId: $scope.tender.id,


        };

         let docData = {
                // 08305644
                confidentiality: $scope.tender.procuringEntity.identifier.id === '08305644' ? "buyerOnly" : 'public'
                    }

            if($scope.tender.procuringEntity.identifier.id === '08305644'){
                docData = {
                // 08305644
                    confidentiality: $scope.tender.procuringEntity.identifier.id === '08305644' ? "buyerOnly" : 'public',
                    confidentialityRationale: $scope.tender.procuringEntity.identifier.id === '08305644' ?
                        "Файл підпису замовника позначено як конфіденційний з міркувань безпеки" : false,
                    }
                }

            console.log('check')

        let procurementMethod = $scope.tender.procurementMethodType
        if ((procurementMethod !== 'reporting'
                || (!$scope.checkDefenseReporting && (procurementMethod === 'reporting' && $scope.tender.value.amount > 50000)) || $scope.checkDefenseReporting)
            && (procurementMethod !== 'belowThreshold' || (procurementMethod === 'belowThreshold' && $scope.tender.procurementMethodRationale === 'simple'))
        && $scope.tender.procuringEntity.kind !== 'other' ){


            return documentsService.sign(data, {
                checkPerson: checkPerson,
                ...docData

            })

                .then(data => {
                    console.log('documentsService.sign data', data);
                    // $scope.awardSign.signed = true;
                    return data;
                })
                .catch(error => console.log('documentsService.sign error', error));
        } else {

            return documentsService.sign(data, {
                checkPerson: checkPerson,
                ...docData
            })

                .then(data => {
                    console.log('documentsService.sign data', data);
                    // $scope.awardSign.signed = true;

                    return data;
                })
                .catch(error => console.log('documentsService.sign error', error));
        }
    }


    $scope.publish = function () {
        $scope.disablePublishBtn = true;
        $scope.$broadcast('show-errors-check-validity');

        $scope.tender.items.forEach(item => {
            if (item.additionalClassifications) item.additionalClassifications = clarifyAdditionalClassifications(item);
        });

        if ($scope.isComefromPlans) {
            const firstPlanClassif = prefixClassifier($scope.plan.classification.id);
            for (const item of $scope.tender.items) {
                if (firstPlanClassif !== item.classification.id.substr(0, firstPlanClassif.length)) {
                    ntFlash.error(gettext('Классификаторы ДК021 должны быть из одной группы (первые {{n}} цифры)').replace('{{n}}', firstPlanClassif.length));
                    $scope.disablePublishBtn = false;
                    return;
                }
            }
        }


        if(!$scope.critereiaFormValid.criteriaValid){
            ntFlash.error(gettext('Критерии заполнены не полностью'));
            $scope.disablePublishBtn = false;
            return;

        }

        if($scope.critereiaFormValid.criteriaPristine){
            ntFlash.error(gettext('Есть не сохранненые критерии'));
            $scope.disablePublishBtn = false;
            return;

        }


        if (!$scope.createTenderForm.$valid) {
            ntFlash.error(gettext('Заполните обязательные поля (подчеркнуты красным)'));
            $scope.disablePublishBtn = false;
            return;
        }

        if(!$scope.tender.milestones) {
            ntFlash.error(gettext('Не заполнены этапы оплаты'));
            $scope.disablePublishBtn = false;
            return;
        }

        if (!$scope.isMultilotTender && $scope.tender.milestones && $scope.tender.milestones.length && !milestonesSumValid()) {
            ntFlash.error(gettext('Сумма значений размеров оплаты всех этапов должна быть равна 100%'));
            $scope.disablePublishBtn = false;
            return;
        }

        if ($scope.tender.items && $scope.tender.items.length == 0) {
            ntFlash.error(gettext('Наличие Предмета Закупки обязательно'));
            $scope.disablePublishBtn = false;
            return;
        }



        if ($scope.tender.milestones && $scope.tender.milestones.length == 0 &&
            !['esco', 'closeFrameworkAgreementSelectionUA', 'competitiveDialogueEU.stage2', 'competitiveDialogueUA.stage2'].includes($scope.tender.procurementMethodType) && !$scope.checkDefenseReporting) {
            ntFlash.error(gettext('Наличие Условия оплаты обязательно'));
            $scope.disablePublishBtn = false;
            return;
        }
        console.log($scope.tender.procurementMethodType)
        if ($scope.isMultilotTender && !$scope.isCloseFrameworkAgreement) {
            const someLotHasGuarantee = $scope.tender.lots.some(lot => lot.guarantee && lot.guarantee.amount);
           if (!criteriaService.getGuaranteeCriterias('CRITERION.OTHER.BID.GUARANTEE') && someLotHasGuarantee ||
                criteriaService.getGuaranteeCriterias('CRITERION.OTHER.BID.GUARANTEE')  && !someLotHasGuarantee            ) {
                ntFlash.error(gettext('Наличие Критериев гарантии вместе с Суммой электронной гарантии в лоте обязательно'));
                $scope.disablePublishBtn = false;
                return;
            }
        } else {
            const tenderHasGuarantee = $scope.tender.guarantee && $scope.tender.guarantee.amount;
            if ((!criteriaService.getGuaranteeCriterias('CRITERION.OTHER.BID.GUARANTEE')  && tenderHasGuarantee ||
                criteriaService.getGuaranteeCriterias('CRITERION.OTHER.BID.GUARANTEE')  && !tenderHasGuarantee) && !$scope.isCloseFrameworkAgreement
            ) {
                ntFlash.error(gettext('Наличие Критериев гарантии вместе с Суммой электронной гарантии в тендере обязательно'));
                $scope.disablePublishBtn = false;
                return;
            }
        }
        // console.log($scope.tender);
        // return;

        // Update company legalName in identifier
        if ($scope.tender.procuringEntity.name) {
            $scope.tender.procuringEntity.identifier.legalName = $scope.tender.procuringEntity.name;
        }
        if ($scope.tender.procuringEntity.name_en) {
            $scope.tender.procuringEntity.identifier.legalName_en = $scope.tender.procuringEntity.name_en;
        }

        var isDatesValid = true,
            stepValidation = true;

        console.log($scope.critereiaFormValid)
        // return;

        if($scope.tender.procurementMethodType === "reporting" && (!$scope.tender.status || $scope.tender.status === 'draft') && $scope.createTenderForm.$dirty){
            ntFlash.error(gettext('Збережіть зміни перед публікацією'));
            $scope.disablePublishBtn = false;
            return;
        }

        if ($scope.tender.procurementMethod !== 'limited' && $scope.tender.procurementMethodType !== 'reporting' ) {
            //validation for isCloseFrameworkAgreement todo validation for isCloseFrameworkAgreement
            // console.log($scope.tender);
            // console.log($scope.tender.procurementMethod);
            if (!$scope.isCloseFrameworkAgreement) {
                isDatesValid = validateService.date({
                    tenderPeriod: {
                        start: $scope.tender.tenderPeriod.startDate,
                        end: $scope.tender.tenderPeriod.endDate
                    },
                    deliveryDate: $scope.tender.items,
                    enquiryPeriod: {
                        start: $scope.tender.enquiryPeriod.startDate,
                        end: $scope.isCompDialogStage2 ? null : $scope.tender.enquiryPeriod.endDate
                    },
                    tenderProcedure: $scope.tender.procurementMethodType
                });

                if ($scope.tender.procurementMethodType === 'belowThreshold') {
                    console.log($scope.tender)
                    // stepValidation = validateService.step($scope.tender.value.amount, $scope.tender.minimalStep.amount);
                    stepValidation = true;

                    // if (!$scope.withFunder.isChecked) {
                    //     $scope.tender.funders = [];
                    //     //delete($scope.tender.funders);
                    // }
                }
            }
        }

        if ($scope.isCloseFrameworkAgreement && isCreateMode) {
            $scope.tender.agreements = [];
            $scope.tender.agreements.push({
                id: $scope.agreementId
            });
        }

        // Remove after 11.12.2018
        if (!$scope.OPC_MAIN_PROCUREMENT_CATEGORY && $scope.tender.mainProcurementCategory) {
            delete $scope.tender.mainProcurementCategory;
        }

        var lotsValidation = $scope.isMultilotTender ? validateService.lots($scope.tender.lots, $scope.tender.items) : true;

        // Uncomment below for validate items cpv by category for block create and edit procedure
        // if (!$scope.validateItemsCpvByCategory()) {
        //     $scope.disablePublishBtn = false;
        //     return
        // }

        // if (!$scope.isCZO &&
        //     (!$scope.tender.plans || $scope.tender.plans.length == 0) &&
        //     (!['belowThreshold','reporting'].includes($scope.tender.procurementMethodType) ||
        //      ($scope.tender.procurementMethodType === 'belowThreshold' && $scope.tender.procurementMethodRationale === 'simple'))) {
        //         ntFlash.error(gettext('Закупівля такого типу має буди зв\'язана з Річним Планом'));
        //         $scope.disablePublishBtn = false;
        //         return;
        // }

        // tenderService.publish($scope.tender.id)
        if($scope.checkDefenseReporting && $scope.tender.value && $scope.tender.value.amount === 0){
            delete $scope.tender.value
        }

        if ($scope.createTenderForm.$valid && isDatesValid && stepValidation && lotsValidation) {

            if ($scope.isCZO) {
                $scope.tender.buyers.forEach(function(item) {
                    item.identifier.legalName = item.name;
                });
            }

            let procurementMethod = $scope.tender.procurementMethodType
            if ($scope.isCreateMode || $scope.tender.status === 'draft') {
                let isPlan = !!($scope.tender.plans);
                if(!$scope.isPMT(['belowThreshold', 'reporting', 'negotiation', 'negotiation.quick', 'closeFrameworkAgreementSelectionUA'])){
                    $scope.tender.tenderPeriod.startDate = $filter('date')(new Date(), DATETIME_FROMAT)
                    delete $scope.tender.criteria
                    console.log($scope.tender)
                    tenderService.saveLocalDraft($scope.tender, false, isPlan, true, $scope.config)
                        .then((data) => {

                            $scope.sign(true)
                                .then((data)=>{
                                    console.log(data)
                                    if(data.ap){
                                        publishRequest()
                                    }
                                    // publishRequest()
                                })

                            })
                        .catch(error => {
                            console.log('tenderService.saveLocalDraft error', error);
                        })
                } else {
                    if ((procurementMethod !== 'reporting'
                            || (!$scope.checkDefenseReporting && (procurementMethod === 'reporting' && $scope.tender.value.amount > 50000)) || $scope.checkDefenseReporting)
                        && (procurementMethod !== 'belowThreshold' ||
                            (procurementMethod === 'belowThreshold' && $scope.tender.procurementMethodRationale === 'simple'))
                        && $scope.tender.procuringEntity.kind !== 'other') {

                        $scope.sign(true)
                            .then((data)=>{
                                if(data && data.ap) {
                                    publishRequest()
                                }
                                // publishRequest()
                            })
                    } else {
                        $scope.sign(true)
                            .then((data)=>{

                                if(data && data.ap) {
                                    publishRequest()
                                }
                                // publishRequest()
                            })
                        // publishRequest()
                    }
                }
            } else {
                if(!$scope.isPMT(['belowThreshold', 'reporting', 'negotiation', 'negotiation.quick'])){
                    delete $scope.tender.criteria
                }

                if ((procurementMethod !== 'reporting' || (!$scope.checkDefenseReporting || (procurementMethod === 'reporting' && $scope.tender.value && $scope.tender.value.amount > 50000)))
                    && procurementMethod !== 'belowThreshold'
                    && $scope.tender.procuringEntity.kind !== 'other'
                ) {

                    $scope.sign(()=>{console.log('signed')})
                        .then((data)=>{
                            if(data && data.ap) {
                                EditTenderRequest()
                            }
                        })

                } else {
                    EditTenderRequest()
                }


            }


        } else {
            if (isDatesValid) {
                ntFlash.error(gettext('Заполните обязательные поля (подчеркнуты красным)'));
            }
            $scope.disablePublishBtn = false;
        }
    };

    function publishRequest(){
        tenderService.publish($scope.tender.id)
            .then((result) => {
                console.log('PUBLIC TENDER response', result);
                let successMessage = gettext('Тендер створений. Для публікації необхідний підпис ключем КЕП');
                console.log($scope.tender.id);
                if ($scope.isCompetitiveDialogue && $scope.files.length > 0) {
                    documentsService
                        .uploadFilesList($scope.files, result.data.response)
                        .then(() => {
                            ntFlash.success(successMessage);
                            $scope.disablePublishBtn = false;
                            $timeout(() => {
                                $state.go('tenderView.overview', {
                                    id: $scope.tender.id
                                }, {
                                    reload: true
                                });
                            }, 1500);
                        });
                } else {
                    ntFlash.success(successMessage);
                    $scope.disablePublishBtn = false;
                    if ($scope.tender.procurementMethodType !== 'closeFrameworkAgreementSelectionUA') {
                        showAttachDocsModal($scope.tender.id);
                    } else {
                        ntFlash.success(successMessage);
                        $scope.disablePublishBtn = false;
                        $timeout(() => {
                            console.log('test', $scope.tender.id)
                            $state.go('tenderView.overview', {
                                id: $scope.tender.id
                            }, {
                                reload: true
                            });
                        }, 1500);
                    }
                }
            })
            .catch((error) => {
                console.log('criteriaService.saveCriterias error', error);
                let errors = error.data.errors.map((el) => {
                    return el.description
                })
                this.ntFlash.error(errors.join(' '))
                $scope.disablePublishBtn = false;
            });
    }

    function EditTenderRequest(){
        tenderService.edit($scope.tender.id, $scope.tender, $scope.config)
            .then(() => {
                if ($scope.isCompetitiveDialogue && $scope.files.length > 0) {
                    documentsService
                        .uploadFilesList($scope.files, $scope.tender.id)
                        .then(() => {
                            ntFlash.success(gettext('Тендер успешно изменен. Не забудьте подписать ключом КЕП'));
                            $scope.disablePublishBtn = false;
                            $timeout(() => {
                                $state.go('tenderView.overview', {
                                    id: $scope.tender.id
                                }, {
                                    reload: true
                                });
                            }, 1500);
                        });
                } else {
                    $state.go('tenderView.overview', {
                        id: $scope.tender.id
                    }, {
                        reload: true
                    });
                    ntFlash.success(gettext('Тендер успешно изменен. Не забудьте подписать ключом КЕП'));
                    $scope.disablePublishBtn = false;
                }

            }, () => $scope.disablePublishBtn = false);
    }

    $scope.addPlanId = function() {
        if ($scope.tender.value.amount && $scope.tender.items[0].classification.id)
            return $uibModal.open({
                templateUrl: "/static/scripts/modules/TenderEdit/views/modals/tender-edit.select-plan.modal.html",
                controller: "tenderEditSelectPlanModalCtrl",
                resolve: {
                    data: function() {
                        return {
                            plans: $scope.tender.plans,
                            value: $scope.tender.value,
                            procurementMethodType: $scope.tender.procurementMethodType,
                            items: $scope.tender.items
                        }
                    }
                },
                backdrop: 'static'
            });
        else ntFlash.error(gettext('Спочатку введіть Очікувану вартість та Предмет закупівлі'));
    }

    $scope.deletePlanId = function() {
        delete $scope.tender.plans;
    }

    $scope.$on('addPlanId', function (event, data) {
        if (data) $scope.tender.plans = [
            {
                id: data
            }
        ];
        // $state.go(currentState);
    });

    $scope.$on('criteriaChanged',  (event, data) => {
        console.log(data)

        $scope.critereiaFormValid = {
            criteriaValid: data.criteriaValid,
            criteriaPristine: data.criteriaPristine,
            criteriaDirty: data.criteriaDirty,

        }

    });




    $scope.cancel = function () {
        if ($scope.isCreateMode) {
            $state.go('tendersList.type', {
                list: 'home',
                status: 'all',
                pageNum: 1
            }, {
                reload: true
            });

        } else {
            $state.go('tenderView.overview', null, {
                reload: true
            });
        }
    };

    // --- process lots ---
    $scope.briefLot = (lot) => {
        return lot.id ? `${lot.title} (${lot.description})` : null;
    };

    $scope.addLot = () => {
        $scope.isInputHide = true;
        $state.go($scope.lotRouter);
        console.log($scope.lotRouter)
    };




    $scope.delLot = (lot) => {
        $scope.tender.items = ($scope.tender.items || []).filter((i) => i.relatedLot !== lot.id);
        $scope.tender.milestones = ($scope.tender.milestones || []).filter((i) => i.relatedLot !== lot.id);
        $scope.tender.lots = $scope.tender.lots.filter((l) => l.id !== lot.id);
    };

    $scope.editLot = (lot) => {
        $state.go($scope.lotRouter, {
            lotId: lot.id
        });
    };

    $scope.$on('editLot', (event, lot, items, milestones) => {
        console.log(event)
        if (lot) {
            milestones = milestones || [];
            items = items || [];
            updateLotDescrption(lot, items);

            const isEdit = $scope.tender.lots.some(el => el.id == lot.id);
            let lots = [...$scope.tender.lots];
            if (isEdit) {
                let lotIndex = lots.indexOf(lot);
                lotIndex = (lotIndex == -1) ? 0 : lotIndex;
                lots[lotIndex] = lot;
            } else {
                lots.push(lot);
            }
            $scope.tender.lots = lots;

            let cleanItems = $scope.tender.items.filter(i => i.relatedLot !== lot.id);
            $scope.tender.items = [...cleanItems, ...items];

            let cleanMilestones = $scope.tender.milestones ? $scope.tender.milestones.filter(i => i.relatedLot !== lot.id) : [];
            $scope.tender.milestones = [...cleanMilestones, ...milestones];

            if (!$scope.isEsco && !$scope.isCloseFrameworkAgreement) {
                $scope.tender.value.amount = 0;
                if ($scope.tender.minimalStep) {
                    $scope.tender.minimalStep.amount = 0;
                }

                for (let i = 0; i < $scope.tender.lots.length; i++) {
                    $scope.tender.value.amount += $scope.tender.lots[i].value.amount;
                    if ($scope.tender.minimalStep) {
                        $scope.tender.minimalStep.amount += $scope.tender.lots[i].minimalStep.amount;
                    }
                }
                $scope.tender.value.amount = +Number($scope.tender.value.amount).toFixed(2);

                $scope.calcPercentages();
            }
        } else {
            if ($scope.isLotAdded) {
                $scope.isInputHide = false;
            }
        }

        $scope.isSingleLot = ($scope.isMultilotTender) ? $scope.tender.lots.length < 2 : false
        criteriaService.setSingleLot($scope.isSingleLot)
        $state.go(currentState);
    });
    // -----------------------------------

    // --- process features --------------
    let updateFeaturesTitle = (data) => {
        if (data && data.length > 0) {
            var resultString = '';

            if (data[0].title) {
                data.forEach(function (val) {
                    resultString += val.title + ' (' + val.description + ') ';
                });
            }

            $scope.featuresTitle = resultString;
        } else {
            $scope.featuresTitle = gettext('Неценовые критерии');
        }
    };

    updateFeaturesTitle($scope.tender.features);

    $scope.clearQualityIndicator = function () {
        $scope.$emit('addQualities', null);
    };

    $scope.$on('addQualities', function (event, data) {
        if (data) {
            $scope.tender.features = data;

            updateFeaturesTitle($scope.tender.features);
        }

        $state.go(currentState);
    });

    // -----------------------------------

    $scope.clearList = (target) => {
        if (target) {
            target.length = 0;
        }
    };

    $scope.calcPercentages = function (isPercentages) {
        if (!$scope.tender.minimalStep) {
            return;
        }
        if (isPercentages) {
            $scope.tender.minimalStep.amount = +($scope.tender.value.amount * $scope.tender.minimalStep.percentages / 100).toFixed(2);
        } else {
            $scope.tender.minimalStep.percentages = +($scope.tender.minimalStep.amount / $scope.tender.value.amount * 100).toFixed(2);
        }
    };

    if ($scope.tender.procurementMethod !== "limited") {
        $scope.calcPercentages();
    }

    $scope.$watchGroup(['tender.value.currency', 'tender.value.amount'], () => {
        if (!$scope.isEsco) {
            if ($scope.tender.value && $scope.tender.value.amount && $scope.euLimits) {
                let isAboveEuLimit = $scope.tender.value.amount > $scope.euLimits[$scope.tender.value.currency];
                $scope.isMultilingualRequired = checkMultilingualRequired(isAboveEuLimit);
            }
        }
    });

    $scope.files = [];

    $scope.$watch('file', (files) => {
        if (files && files.length) {
            files.forEach(file => {
                file.status = 'waiting';
                $scope.files.push(file);
            });
        }
    });

    $scope.isLotAdded = false;
    $scope.$watch('tender.lots', (lotArray) => {
        if (lotArray) {
            $scope.isLotAdded = lotArray.length == 0 ? true : false;
        }
        $scope.isSingleLot = ($scope.isMultilotTender) ? $scope.tender.lots.length < 2 : false
        criteriaService.setSingleLot($scope.isSingleLot)
        if ($scope.isLotAdded) {
            $scope.isInputHide = false;
        } else {
            $scope.isInputHide = true;
        }
    });

    $scope.$watch('tender.items[0].classification.id', (cpvId) => {
        if (!$scope.tender.mainProcurementCategory) {
            if (cpvId) {
                let code = parseInt(cpvId.slice(0, 3), 10);
                if (code >= 30 && code <= 449) {
                    $scope.tender.mainProcurementCategory = 'goods';
                } else if (code >= 450 && code <= 454) {
                    $scope.tender.mainProcurementCategory = 'works';
                } else {
                    $scope.tender.mainProcurementCategory = 'services';
                }
            }
        }
    });

    $scope.deleteDoc = (index) => {
        $scope.files.splice(index, 1);
    };

    $scope.addBuyersField = function () {
        $scope.tender.buyers.push({
            identifier: {
                scheme: 'UA-EDR',
                id: '',
                legalName: ''
            },
            name: ''
        });
    };

    $scope.removeBuyersField = function (index) {
        if ($scope.tender.buyers.length > 0) {
            $scope.tender.buyers.splice(index, 1);
        }
    };


    $scope.alert = (message, type) => {
        switch (type) {
            case 'success': ntFlash.success(gettext(message)); break;
            case 'error': ntFlash.error(gettext(message)); break;
        }
    }


    $scope.signCheck = ()=>{
        return $scope.tender.procurementMethodType === 'aboveThresholdUA' || $scope.tender.procurementMethodType === 'aboveThresholdEU' || $scope.tender.procurementMethodType === 'esco'
    }

        // $scope.checkCriteria=(id)=>{
        //
        //     return ['CRITERION.OTHER.CONTRACT.GUARANTEE','CRITERION.OTHER.BID.GUARANTEE'].includes(id)
        // }
    $scope.test = ()=>{

        return ($scope.createTenderForm.$invalid || $scope.disablePublishBtn || $scope.checkCriteria())
    }
    $scope.createDraftTender = (create = true) =>{
        if ($scope.tender.status && $scope.tender.status !== 'draft') return false;
        if (!$scope.tender.title) {
            ntFlash.error(gettext('Назва процедури відсутня'));
            return;
        }

        if(!$scope.checkDefenseReporting && $scope.tender.procurementMethodType === 'reporting' && $scope.tender.value.amount < 50000){

            let error = false
            $scope.tender.items.forEach((el)=>{
                if(el.deliveryAddress.locality == ''){
                    ntFlash.error(gettext('Додайте адрессу до предметів закупівлі'));
                    error = true
                }
            })
            if(error){
                return
            }

        }
        console.log($scope.tender)
        console.log($scope.config)

        let isPlan = !!($scope.tender.plans);

        $scope.disablePublishBtn = true;
        $scope.$broadcast('show-errors-check-validity');
        // TODO temp result
        delete $scope.tender.criteria


        let request = create ? tenderService.createDraft($scope.tender, true, isPlan,  $scope.config) : tenderService.saveLocalDraft($scope.tender, false, isPlan, false, $scope.config);
       request
            .then(result => {
                console.log('tenderService.saveLocalDraft result', result);
                ntFlash.success('Чернетку збережено');
                $timeout(() => {
                    if(create){
                        $state.go('tenderView.edit', {
                            id: result.data.response
                        }, {
                            reload: true
                        });
                    } else {
                        $state.go('tenderView.edit', {
                            id: $scope.tender.id
                        }, {
                            reload: true
                        });
                    }
                }, 1500);

            })
            .catch(error => {
                console.log('tenderService.saveLocalDraft error', error);
            })
            .finally(() => $scope.disablePublishBtn = false);
    }


    $scope.checkCriteria = ()=>{

        if(['belowThreshold', 'reporting', 'negotiation', 'negotiation.quick',  'simple.defense', 'reporting', 'closeFrameworkAgreementSelectionUA'].includes( $scope.tender.procurementMethodType)){
            // console.log('return false')
            return false
        } else {
            // console.log(!criteriaService.criteria)
            return !criteriaService.criteria
        }

    }

    // $scope.saveDraftTender = () => {
    //
    // }
    //
    // $scope.testBindings = (api)=>{
    //     console.log(api);
    //
    //     $scope.isGuaranteeCriteriasActive
    // }


});


